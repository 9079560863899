import { getQueryParams } from 'app/utils/queryParams';
import { WithWording, Wording } from 'app/utils/types';

const CLIENT_WORDING = {
  your: 'tu',
  yours: 'tus',
  wouldBe: 'te',
  suggestion: 'te',
  start: 'comienzas',
  begin: 'empieza',
  willHave: 'tendrás',
  willGoTo: 'irás',
  retirement: 'jubiles',
  willSave: 'habrás',
  willWin: 'ganarás',
  futureSavings: 'ahorrarás',
  couldWin: 'podrías',
  currentSavings: 'ahorras',
  wouldReceive: 'Recibirías',
  insure: 'asegura',
  aported: 'aportaste',
  willReach: 'lograrás',
  append: 'sumas',
};

type ContentStructure = typeof CLIENT_WORDING;

const EXECUTIVE_WORDING = {
  your: 'su',
  yours: 'sus',
  wouldBe: 'se',
  suggestion: 'le',
  start: 'comienza',
  begin: 'empiece',
  willHave: 'tendrá',
  willGoTo: 'irá',
  retirement: 'jubile',
  willSave: 'habrá',
  willWin: 'ganará',
  futureSavings: 'ahorrará',
  couldWin: 'podría',
  currentSavings: 'ahorra',
  wouldReceive: 'Recibiría',
  insure: 'asegure',
  aported: 'aportó',
  willReach: 'logrará',
  append: 'suma',
};

const WORDING_MAP: Record<Wording, ContentStructure> = {
  CLIENT: CLIENT_WORDING,
  EXECUTIVE: EXECUTIVE_WORDING,
};

export const getWording = () => {
  const DEFAULT_WORDING = WORDING_MAP.CLIENT;
  const { wording: choosenWording } = getQueryParams<WithWording>();

  if (!choosenWording) {
    return { choosenWording: 'CLIENT', wording: DEFAULT_WORDING };
  }

  return { choosenWording, wording: WORDING_MAP[choosenWording] };
};
