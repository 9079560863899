import {
  CompensationPlan,
  Genders,
  RegimeLiteral,
  SavingsOption,
  WithContribution,
  WithCurrencyInfo,
  WithEmptyOption,
} from 'app/utils/types';
import { api } from 'app/utils/api';
import { SIMULATION_ENDPOINTS } from 'app/utils/endpoints';
import { WithPolicyByYearData } from 'app/utils/hooks/useBuildTable';
import { RiskProfileVariants } from '../mutualFunds/mutualFundsService';

export interface ApvLifePayload extends WithEmptyOption, WithContribution {
  gender: Genders
  date_of_birth: string
  net_salary: number
  afp_balance: number
  projection_rate: number
  compensation_plan: CompensationPlan
  death_insured_capital: number
  accidental_death_insured_capital: number
  agreed_premium: number
  itp_insured_capital: number
  risk_profile: RiskProfileVariants
  apv_regime: Uppercase<RegimeLiteral>
  savings_option: SavingsOption,
  ideal_pension?: number,
  expected_retirement?: number
}

export interface ApvLifeResponse extends WithCurrencyInfo, WithPolicyByYearData {
  average_monthly_cost: number
  current_year_fiscal_contribution: number
  current_year_personal_contribution: number
  monthly_insurance_cost: number
  monthly_pension_with_savings: number
  monthly_pension_without_savings: number
  monthly_savings: number
  monthly_pension_with_afp: number
  monthly_pension_afp_with_apv_transfer: number
  total_fiscal_contribution_without_interest: number
  total_personal_savings_without_interest: number
  total_savings_interest: number
  total_insurance_cost: number
  total_savings: number
}

export const apvLifeService = {
  simulate: async (payload: ApvLifePayload) => api
    .post<ApvLifeResponse>(SIMULATION_ENDPOINTS.APV_LIFE, payload),
};
