import { RiskProfileVariants } from 'app/components/simulations/mutualFunds/mutualFundsService';
import { SimulationType } from './types';

export const SIMULATION_NAMES: Record<SimulationType, string> = {
  INSURANCE_WITH_SAVINGS: 'APV con Ahorros',
  APV: 'APV',
  APV_LIFE: 'APV Life',
  FULL_FLEXIBLE: 'Full Flexible',
  MUTUAL_FUNDS: 'Fondos Mutuos',
  PROVISIONAL_SAVINGS: 'APV Start',
  INSURED_UNIVERSITY: 'Universidad Asegurada',
};

export const POLICY_PROJECTION_URL = '/simulations/policy-projection';

interface RiskProfileMetadata {
  percentage: number
  label: string
  afpPercentage: number
}

export type SummarizedRiskProfiles = Extract<RiskProfileVariants, 'conservative' | 'moderate' | 'aggressive'>;

export const RISK_PROFILE_METADATA: Record<SummarizedRiskProfiles, RiskProfileMetadata> = {

  aggressive: {
    label: 'agresiva',
    percentage: 4.87,
    afpPercentage: 4.49,
  },
  moderate: {
    label: 'balanceada',
    percentage: 3.31,
    afpPercentage: 3.7,
  },
  conservative: {
    label: 'conservadora',
    percentage: 1.4,
    afpPercentage: 2.49,
  },

};
