import React from 'react';
import { Footer, NavBar, Table } from 'app/components/common';
import { RawColumn, RowShape, useBuildTable } from 'app/utils/hooks/useBuildTable';
import { PolicyProjectionContainerHeader } from './PolicyProjectionContainerHeader';
import { PolicyCostsAndSavingsSection } from './PolicyCostsAndSavingsSection';
import { PolicyHelpSection } from './PolicyHelpSection';

interface PolicyProjectionContentProps {
  title: string
  columns: RawColumn[]
  rows: RowShape[]
  costs: number[]
  yearly_contributions: number[]
}

export function PolicyProjectionContent({
  columns, rows, costs, yearly_contributions, title,
}: PolicyProjectionContentProps) {
  const { table } = useBuildTable({ columns, rows });

  return (
    <div>
      <NavBar />

      <div id="policy-projection-content-body" className="mx-5 px-5 my-4">
        <section className="mb-4">
          <PolicyProjectionContainerHeader title={title} />

          {/*
            NOTE:
            Costs and Savings projection chart is hidden (temporarily) by client requirement
            Once client requests the chart displaying, uncomment these lines

            <PolicyCostsAndSavingsSection
              costs={costs}
              yearly_contributions={yearly_contributions}
            />
          */}
          <Table table={table} tableClassName="policy-table" />
        </section>

        <PolicyHelpSection title={title} />
      </div>

      <Footer />
    </div>

  );
}
