import { CurrencyOption } from './types';

const transformToUF = (clp: number, ufRatio: number) => clp / ufRatio;

const transformToCLP = (uf: number, ufRatio: number) => uf * ufRatio;

export const transformCurrencyMap: Record<CurrencyOption,
(value: number, ratio: number) => number> = {
  clp: transformToCLP,
  clf: transformToUF,
};
