/* eslint-disable react/no-unstable-nested-components */
import { HorizontalStackBarChart } from 'app/components/charts';
import {
  Block, BlockTitle, CardChart, FieldDisplayer, FieldDisplayerCard, LoadingSpinner,
} from 'app/components/common';
import { getWording } from 'app/constants/wording/wording';
import {
  SimulationProductError,
  StackedBarChartConfig,
  WithCurrencySwitch,
  WithPolicyProjectionCTA,
} from 'app/utils/types';
import React from 'react';
import { MEDIA_QUERIES, useMediaQuery } from 'app/utils/hooks/useMediaQuery';
import { getIcon } from 'app/utils/staticStorage';
import { AxiosError } from 'axios';
import { useTransformCurrency as r } from 'app/utils/hooks/useTransformCurrency';
import { useCurrencyContext } from 'app/contexts/CurrencyContext';
import { CurrencySwitch } from 'app/components/common/CurrencySwitch';
import { CTACard } from 'app/components/common/CTACard';
import { createUrlWithQueryParams, getQueryParams } from 'app/utils/queryParams';
import { POLICY_PROJECTION_URL } from 'app/utils/constants';
import { InformativeDeclarationDisclaimer } from 'app/components/common/InformativeDeclarationDisclaimer';
import clsx from 'clsx';
import { FullFlexibleResponse } from './fullFlexibleService';
import { useFullFlexible } from './useFullFlexible';
import { SimulationError } from '../SimulationError';

interface FullFlexibleContentProps extends WithCurrencySwitch, WithPolicyProjectionCTA {
  simulationResult: FullFlexibleResponse
  chartData: StackedBarChartConfig
  containerClassname?: string
}

function FullFlexibleContent({
  simulationResult,
  chartData,
  containerClassname,
  showCurrencySwitch,
  showPolicyProjectionCTA,
}: FullFlexibleContentProps) {
  const {
    goal,
    timeframe,
    agreed_premium,
    monthly_insurance_cost,
    average_monthly_cost,
  } = simulationResult;
  const { wording } = getWording();
  const { currencyPrefix } = useCurrencyContext();
  const queryParameters = getQueryParams();

  return (
    <section className="h-100 d-flex flex-column" style={{ width: 750 }}>

      <div className={containerClassname ?? 'simulation-renderer'}>
        {showCurrencySwitch && <CurrencySwitch />}

        <Block className="mb-3">
          <BlockTitle
            extraClassName="h6 mb-3"
            primaryText={{ content: `Si ${wording.start} hoy a ahorrar con ${wording.your} seguro`, color: 'text-primary' }}
            secondaryText={{ content: 'Full Flexible:' }}
          />

          <div className="row gx-4 gy-3 mb-3">
            <div className="col-md-6">
              <FieldDisplayerCard
                iconName="circle-hand-money-info.svg"
                renderText={() => (
                  <>
                    <span>Para lograr </span>
                    <span className="text-info">{`${wording.your} objetivo `}</span>
                    <span>de:</span>
                  </>
                )}
                renderFieldDisplayer={() => (
                  <FieldDisplayer
                    overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-info"
                    primaryText={{ content: currencyPrefix, color: 'text-info' }}
                    secondaryText={{ content: r(goal), color: 'text-dark' }}
                  />
                )}
              />
            </div>

            <div className="col-md-6">
              <FieldDisplayerCard
                iconName="circle-calendar-info.svg"
                renderText={() => (
                  <>
                    <span>Durante </span>
                    <span className="text-info">un período </span>
                    <span>de:</span>
                  </>
                )}
                renderFieldDisplayer={() => (
                  <FieldDisplayer
                    overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-info"
                    primaryText={{ content: timeframe.toString(), color: 'text-dark' }}
                    secondaryText={{ content: timeframe === 1 ? 'mes' : 'meses', color: 'text-info' }}
                  />
                )}
              />
            </div>

            <div className="col-md-6 mx-auto">
              <FieldDisplayerCard
                iconName="circle-umbrella.svg"
                renderText={() => (
                  <>
                    <span className="text-capitalize">{`${wording.your} `}</span>
                    <span>seguro tiene una </span>
                    <span className="text-secondary">prima total a pagar mensual </span>
                    <span>de:</span>
                  </>
                )}
                renderFieldDisplayer={() => (
                  <FieldDisplayer
                    overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-secondary"
                    primaryText={{ content: currencyPrefix, color: 'text-secondary' }}
                    secondaryText={{ content: r(agreed_premium), color: 'text-dark' }}
                  />
                )}
              />
            </div>
          </div>
        </Block>

        <Block className="mb-3">
          <CardChart
            renderText={() => (
              <span className="d-block mb-2 fw-normal fs-small text-dark text-center">
                <span className="text-capitalize">{`${wording.your} `}</span>
                <span className="text-secondary">total de ahorros </span>
                <span>
                  {`en ${wording.your}  Seguro Full Flexible `}
                </span>
                <span className="text-secondary fw-bold">{`a los ${timeframe} meses `}</span>
                <span>podría ser de: </span>
              </span>
            )}
            renderFieldDisplayer={() => (
              <div className="row justify-content-center">
                <FieldDisplayer
                  overwrittenClassName="w-max-content fw-bold fs-1 px-2_5 py-2 d-inline-block rounded-4 bg-light-secondary"
                  primaryText={{ content: currencyPrefix, color: 'text-secondary' }}
                  secondaryText={{ content: r(goal), color: 'text-dark' }}
                />
              </div>
            )}
            renderChart={() => (
              <HorizontalStackBarChart
                chartData={chartData}
                renderJoin={(columnWidth) => <div className={clsx('text-dark fs-small fw-bold', columnWidth)}>+</div>}
              />
            )}
          />
        </Block>

        {showPolicyProjectionCTA && (
          <Block className="mb-3">
            <CTACard
              icon={{
                src: getIcon('circle-shield-star.svg'),
                alt: '',
              }}
              renderContent={() => (
                <div className="text-center fs-small">
                  <p className="mb-2">
                    <span>El </span>
                    <span className="text-secondary">costo promedio mensual </span>
                    <span>{`de ${wording.your} Seguro Full Flexible sería de `}</span>
                    <span className="fw-bold">{`${r(average_monthly_cost, { withPrefix: true })} `}</span>
                    <span>y la </span>
                    <span className="text-secondary">prima básica </span>
                    <span>sería de </span>
                    <span className="fw-bold">{`${r(monthly_insurance_cost, { withPrefix: true })}. `}</span>
                  </p>

                  <p className="m-0">
                    <span>Revisa como irán variando a través de los años.</span>
                  </p>
                </div>
              )}
              renderCTA={() => (
                <a
                  target="_blank"
                  className="btn btn-outline-primary"
                  rel="noreferrer"
                  href={createUrlWithQueryParams(
                    POLICY_PROJECTION_URL, queryParameters as Record<string, string>
                  )}
                >
                  Ver proyección
                </a>
              )}
            />
          </Block>
        )}

        <Block className="mb-3">
          <InformativeDeclarationDisclaimer />
        </Block>
      </div>
    </section>
  );
}

function EmptyContent() {
  const isMobile = useMediaQuery(MEDIA_QUERIES.MOBILE);
  const { wording } = getWording();

  return (
    <section className="h-100" style={{ width: isMobile ? '100%' : 800 }}>
      <div className="simulation-renderer h-100 d-flex align-items-center justify-content-center">
        <figure className="d-flex flex-column  align-items-center justify-content-center">
          <img src={getIcon('rocket.svg')} alt="Emtpy simulation state" className="mb-2" />

          <figcaption className="text-primary text-wrap text-center h6 fw-bold lh-base" style={{ width: 200 }}>
            {`Aquí verás como mejora ${wording.your} futuro`}
          </figcaption>
        </figure>
      </div>
    </section>
  );
}

interface FullFlexibleProps extends WithCurrencySwitch, WithPolicyProjectionCTA {
  containerClassname?: string
}

export function FullFlexible({
  containerClassname,
  showCurrencySwitch,
  showPolicyProjectionCTA,
}: FullFlexibleProps) {
  const {
    simulationResult,
    chartData,
    error,
    isError,
    isEmpty,
    isLoading,
  } = useFullFlexible();

  if (isEmpty) {
    return <EmptyContent />;
  }

  if (simulationResult && chartData) {
    return (
      <FullFlexibleContent
        showPolicyProjectionCTA={showPolicyProjectionCTA}
        showCurrencySwitch={showCurrencySwitch}
        simulationResult={simulationResult}
        chartData={chartData}
        containerClassname={containerClassname}
      />
    );
  }

  if (isLoading) return <LoadingSpinner color="primary" size={256} borderWidth={4} />;
  if (isError) return <SimulationError error={error as AxiosError<SimulationProductError>} />;

  return null;
}
