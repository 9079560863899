import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getQueryParams } from 'app/utils/queryParams';
import { SIMULATION_NAMES } from 'app/utils/constants';
import { PolicyProjectionContent } from '../PolicyProjectionContainer/components/PolicyProjectionContent';
import { ApvLifePayload, apvLifeService } from '../simulations/apvLife/apvLifeService';

export function APVLifePolicyProjection() {
  const { is_empty, ...payloadParameters } = getQueryParams<ApvLifePayload>();
  const { data: response } = useQuery({
    queryKey: ['APV_LIFE_POLICY_PROJECTION'],
    queryFn: () => apvLifeService.simulate(payloadParameters),
  });

  const { policy_data_by_year } = response?.data ?? {};
  const {
    columns, rows, costs, yearly_contributions,
  } = policy_data_by_year ?? {};

  return (
    <PolicyProjectionContent
      costs={costs ?? []}
      yearly_contributions={yearly_contributions ?? []}
      title={SIMULATION_NAMES.APV_LIFE}
      columns={columns ?? []}
      rows={rows ?? []}
    />
  );
}
